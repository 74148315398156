import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';

function CustomLinkComponent({ linkConfig, currentPage }) {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = (page) => {
    const hasPageName = (name) => currentPage?.indexOf(name) === 0;
    const isCMSPage = (pageId) => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = (tab) => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
}

function TopbarMobileMenu(props) {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const userRole = currentUser?.attributes?.profile?.publicData?.role;

  const [language, setLanguage] = useState('it');
  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);

    if (selectedLanguage === 'en') {
      window.location.href = 'https://clubjoy.co';
    } else {
      window.location.href = 'https://clubjoy.it';
    }
  };

  const extraLinks = customLinks.map((linkConfig) => (
    <CustomLinkComponent key={linkConfig.text} linkConfig={linkConfig} currentPage={currentPage} />
  ));

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );

    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>
          <div className={css.otherGreeting}>
            Oppure:
            <br />
            <NamedLink name="TeambuildingPage">
              <FormattedMessage id="TopbarDesktop.provider" />
            </NamedLink>
            <br />
            <NamedLink name="TeambuildingPage">
              <FormattedMessage id="TopbarDesktop.team" />
            </NamedLink>
          </div>
          <br />
            <NamedLink name="GiftCardsPage">
              Gift cards
            </NamedLink>
        </div>

        <div className={css.footer}>
         
        </div>

        {/* Language Selector for Unauthenticated Users 
        <div className={css.languageSelectorContainer}>
        <select
  className={classNames(css.languageSelector, css.smallLanguageSelector)} // Add a new class here
  value={language}
  onChange={handleLanguageChange}
>
  <option value="it">It</option>
  <option value="en">Eng</option>
</select>
        </div> */}
      </div>
    );
  }
  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = (page) => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {userRole === 'provider' && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('CMSPage'))}
            name="CMSPage"
            params={{ pageId: 'overview' }}
          >
            <FormattedMessage id="TopbarMobileMenu.overview" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>
        {userRole === 'provider' && (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
          name="ProfileSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('TeambuildingPage'))}
          name="GiftCardsPage"
        >
          Gift card
        </NamedLink>

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('TeambuildingPage'))}
          name="TeambuildingPage"
        >
          <FormattedMessage id="TopbarDesktop.team" />
        </NamedLink>

        <div className={css.languageSelectorContainer}>
          <select
            className={classNames(css.languageSelector, css.smallLanguageSelector)} // Add a new class here
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="it">IT</option>
            <option value="en">EN</option>
          </select>
        </div>

        <div className={css.spacer} />
      </div>
      {userRole === 'provider' && (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      )}
    </div>
  );
}

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;